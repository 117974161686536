import {
  LoadingStatus,
  SessionStatusProviderProps,
  useLoginStatus,
  useSessionIdStorageKey,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useCreateSession: SessionStatusProviderProps["useCreateSession"] = () => {
  const setBackdrop = useSetBackdrop();
  const navigate = useNavigate();
  const client = useApiClient();
  const { isAuthenticated, hasCrmId } = useLoginStatus();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);

      const hasSessionId = !!sessionStorage.getItem(sessionIdStorageKey);
      const isLoggedInAndLinked = isAuthenticated && hasCrmId;

      try {
        setBackdrop(true);
        if (!isCancelled && !hasSessionId) {
          if (!isLoggedInAndLinked) {
            setIsError(true);
          } else {
            const response = await client.createSession();
            sessionStorage.setItem(sessionIdStorageKey, response.result);
          }
        }
      } catch {
        if (!isCancelled) {
          setIsError(true);
          navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL);
        }
      } finally {
        if (!isCancelled && isLoggedInAndLinked) {
          setIsLoading(false);
        }
        setBackdrop(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, hasCrmId, isAuthenticated, sessionIdStorageKey]);

  return isError ? LoadingStatus.Error : isLoading ? LoadingStatus.Loading : LoadingStatus.Successful;
};

export default useCreateSession;
