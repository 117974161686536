import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useRoutes from "../../routing/useRoutes";
import useFlowState from "../useFlowState";
import usePage from "../usePage";

export const sidebarTitle = "Motor glass claim";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const { steps, activeStepIndex, navigateToPreviousStep, canNavigateToPreviousStep } = useRoutes();
  const navigate = useNavigate();
  const flowState = useFlowState();

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick = (_: string, stepIndex: number) => {
    if (!flowState.isFlowCompleted && activeStepIndex !== stepIndex) {
      navigate(steps[`${stepIndex}`].path);
    }
  };

  return {
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
