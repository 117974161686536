import { Link, Typography } from "@mui/material";
import { event, gtm, phoneCall } from "@racwa/analytics";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import { DialogBoxWithButton, InsurancePhoneNumber } from "raci-react-library";
import { useEffect } from "react";

export interface SimilarClaimDialogProps {
  showDialog: boolean;
  eventDate: string;
  claimNumber?: string;
  onButtonClick?: () => void;
}

export const SimilarClaimDialog: React.FC<SimilarClaimDialogProps> = ({
  showDialog,
  claimNumber,
  eventDate,
  onButtonClick,
}: SimilarClaimDialogProps) => {
  useEffect(() => {
    if (showDialog) {
      gtm(event("You've already made a claim for a similar date"));
    }
  }, [showDialog]);

  return (
    <DialogBoxWithButton
      onButtonClick={onButtonClick}
      buttonText="Or make a new claim"
      showDialog={showDialog}
      buttonColor="inherit"
      id="similar-claim-confirmation-dialog"
      data-testid="similar-claim-confirmation-dialog"
      title="You've already made a claim for a similar date"
      content={
        <>
          <Typography paragraph>
            That claim was for motor glass damage on {format(parseISO(eventDate), "dd/MM/yyyy")}.
          </Typography>
          <Typography>
            To continue that claim or ask about it, please call{" "}
            <Link
              href={InsurancePhoneNumber.href}
              onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
            >
              {InsurancePhoneNumber.display}
            </Link>{" "}
            and quote <b>claim number {claimNumber}</b>.
          </Typography>
        </>
      }
      setShowDialog={() => {}}
      hideCloseButton
    />
  );
};

export default SimilarClaimDialog;
