import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { ClaimsMotorGlassApiClient } from "raci-claims-motor-glass-clientproxy";
import { useIsAuthenticated, useSessionIdStorageKey } from "raci-react-library";
import {
  DEFAULT_SHIELD_ENVIRONMENT,
  HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION,
  HTTP_HEADER_KEY_SHIELD_ENVIRONMENT,
  STORAGE_KEY_NPE_RACWA_CRM_ID,
  createMsalClient,
  useFeatureToggles,
  useShieldEnvironment,
} from "raci-react-library-npe";
import { useMemo } from "react";
import { ADB2C_CLIENT_ID } from "../../shared/constants";

const useApiClient = () => {
  const { instance: adb2cInstance } = useMsal();
  const sessionIdStorageKey = useSessionIdStorageKey();

  const npeInstance = createMsalClient(
    process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? "",
    process.env.REACT_APP_NPE_OAUTH_AUTHORITY ?? "",
  );
  const npeAccount = useAccount({
    clientId: process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? undefined,
  });
  const adb2cAccount = useAccount({
    clientId: ADB2C_CLIENT_ID,
  });
  const isAuthenticated = useIsAuthenticated();

  const { selected: selectedShieldEnv } = useShieldEnvironment();
  const [toggles] = useFeatureToggles();

  return useMemo(() => {
    const loginRequestBase = {
      scopes: [],
      redirectUri: process.env.REACT_APP_ADB2C_RETURN_URL,
    };

    const npeLoginRequest = {
      ...loginRequestBase,
      account: npeAccount,
    };

    const adb2cLoginRequest = {
      ...loginRequestBase,
      account: adb2cAccount,
    };

    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let npeToken = "";
      let adb2cToken = "";
      const racwaCrmId = sessionStorage.getItem(STORAGE_KEY_NPE_RACWA_CRM_ID) ?? "";

      if (process.env.REACT_APP_ENVIRONMENT !== "UAT") {
        try {
          const tokenResponse = await npeInstance.acquireTokenSilent(npeLoginRequest);
          npeToken = tokenResponse.idToken;
        } catch (ex) {
          await npeInstance.acquireTokenRedirect(npeLoginRequest);
        }
      }

      // if we have the crm id already, don't send the token even if it's available
      if (!racwaCrmId && isAuthenticated) {
        try {
          const tokenResponse = await adb2cInstance.acquireTokenSilent(adb2cLoginRequest);
          adb2cToken = tokenResponse.idToken;
        } catch {
          await adb2cInstance.acquireTokenRedirect(adb2cLoginRequest);
        }
      }

      const headers: any = { ...init?.headers };
      const sessionId = sessionStorage.getItem(sessionIdStorageKey);
      if (sessionId) {
        headers.SessionId = sessionId;
      }

      toggles?.forEach((toggle) => {
        if (toggle.enabled !== null) {
          headers[toggle.headerName] = toggle.enabled;
        }
      });

      if (selectedShieldEnv && selectedShieldEnv !== DEFAULT_SHIELD_ENVIRONMENT) {
        headers[`${HTTP_HEADER_KEY_SHIELD_ENVIRONMENT}`] = selectedShieldEnv;
      }

      if (npeToken) {
        headers[`${HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION}`] = npeToken;
      }

      if (adb2cToken && !racwaCrmId) {
        headers.Authorization = `Bearer ${adb2cToken}`;
      }

      if (racwaCrmId) {
        headers["RACWA-CRM-ID"] = racwaCrmId;
      }

      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new ClaimsMotorGlassApiClient(process.env.REACT_APP_API_BASE_URL || "", {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npeAccount, selectedShieldEnv, toggles, isAuthenticated]);
};

export { useApiClient };
export default useApiClient;
