import { PhoneNumber } from "raci-react-library";

export const toPhoneNumber = (phoneNumber: string): PhoneNumber => {
  const numberWithoutSpaces = phoneNumber.replaceAll(" ", "");
  return {
    display: phoneNumber,
    displayWithoutSpaces: numberWithoutSpaces,
    href: `tel:${numberWithoutSpaces}`,
  };
};

export default toPhoneNumber;
