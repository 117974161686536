import { event, fieldTouched, gtm } from "@racwa/analytics";
import { useGetSessionState, useSessionState } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormRoute, PreFormRoute, formRouteInfo } from "../../../../shared/routing/routes.config";
import getPolicyOption from "../../../../shared/utils/getPolicyOption";
import { BeforeYouStartState } from "../../../BeforeYouStart/types";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourPolicyFormProps, YourPolicyState } from "../../types";

export const useYourPolicy = (): YourPolicyFormProps => {
  const navigate = useNavigate();
  const [showPolicyNotListedDialog, setShowPolicyNotListedDialog] = useState(false);
  const [getSubmitError, setSubmitError] = useState(false);
  const beforeYouStartState = useGetSessionState<BeforeYouStartState>(PreFormRoute.BeforeYouStart);
  const currentStartYourClaimState = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const claimNumber = currentStartYourClaimState.claimNumber;
  const [yourPolicyState, setYourPolicyState] = useSessionState<YourPolicyState>({
    skipPageTrackingRecalculation: !!claimNumber,
  });

  const form = useForm<YourPolicyState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async (newValues: YourPolicyState) => {
    if (!newValues.policyNumber) {
      setSubmitError(true);
    } else {
      setYourPolicyState({ policyNumber: newValues.policyNumber, isCompleted: true });
      navigate(formRouteInfo[FormRoute.StartYourClaim].path);
    }
  };

  const recordGtmFieldEvent = (registrationNumber?: string) => {
    if (!registrationNumber) {
      gtm(event("No car rego"));
    }
    gtm(fieldTouched("Which car was damaged?"));
  };

  let policyOptions = beforeYouStartState.policies?.map((policyDetails) => getPolicyOption(policyDetails)) ?? [];

  if (claimNumber) {
    policyOptions = policyOptions.filter((policy) => policy.policyNumber === yourPolicyState.policyNumber);
  }

  return {
    form,
    onSubmit,
    showPolicyNotListedDialog,
    setShowPolicyNotListedDialog,
    claimNumber,
    submitError: getSubmitError,
    initialPolicyNumber: yourPolicyState.policyNumber ?? "",
    policyOptions: policyOptions,
    recordGtmFieldEvent,
  };
};

export default useYourPolicy;
