import { AccountIdentifiers } from "@racwa/react-adb2c";
import { useCheckLoginStatus as useCheckLoginStatusReal } from "raci-react-library";

let useCheckLoginStatus: (account: AccountIdentifiers) => ReturnType<typeof useCheckLoginStatusReal>;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { useCheckLoginStatus: useCheckLoginStatusNpe } = require("raci-react-library-npe");
  useCheckLoginStatus = useCheckLoginStatusNpe;
} else {
  useCheckLoginStatus = useCheckLoginStatusReal;
}

export default useCheckLoginStatus;
