import { PolicyDetails } from "raci-claims-motor-glass-clientproxy";
import { SessionState } from "raci-react-library";

export enum RedirectType {
  Login = "login",
  Next = "next",
  Register = "register",
}

export interface BeforeYouStartFormProps {
  isAuthenticated: boolean;
  disabled: boolean;
  hideNcb: boolean;
  handleRedirect: (redirectType: RedirectType) => void;
}

export interface BeforeYouStartState extends SessionState {
  policies?: PolicyDetails[];
}
