import { useMsal } from "@azure/msal-react";
import { event, gtm, virtualPageView } from "@racwa/analytics";
import { ClaimsMotorGlassApiException } from "raci-claims-motor-glass-clientproxy";
import {
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  HTTP_STATUS_CODE_NOT_FOUND,
  LoadingStatus,
  useLoginStatus,
  useSessionState,
  useSessionStatus,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HIDE_NCB,
  STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW,
  STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED,
  STORAGE_KEY_POLICY_NUMBER,
} from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ERROR_DONT_HAVE_A_POLICY_PAGE_URL,
  ERROR_SYSTEM_UNAVAILABLE_PAGE_URL,
  FormRoute,
  formRouteInfo,
} from "../../../../shared/routing/routes.config";
import { YourPolicyState } from "../../../YourPolicy/types";
import { BeforeYouStartFormProps, BeforeYouStartState, RedirectType } from "../../types";

export const useBeforeYouStart = (): BeforeYouStartFormProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const { instance } = useMsal();
  const { isAuthenticated, hasCrmId } = useLoginStatus();
  const sessionStatus = useSessionStatus();
  const [beforeYouStartState, setBeforeYouStartState] = useSessionState<BeforeYouStartState>({
    skipPageTrackingRecalculation: true,
  });
  const [, setYourPolicyState] = useSessionState<YourPolicyState>({
    specificKey: FormRoute.YourPolicy,
    skipPageTrackingRecalculation: true,
  });
  const [disabled, setDisabled] = useState(false);
  const hideNcb = HIDE_NCB;

  const setPolicyNumber = (policyNumber: string) => {
    sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, policyNumber);
    setYourPolicyState({
      policyNumber,
      isCompleted: true,
    });

    setBeforeYouStartState({
      ...beforeYouStartState,
      isCompleted: true,
    });
  };

  const redirectToNextPage = async () => {
    try {
      const enteredWithPolicyNumber = !!sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);
      const policiesPreviouslyFetched = !!beforeYouStartState.policies;

      if (enteredWithPolicyNumber) {
        setPolicyNumber(sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER) ?? "");
      } else if (!policiesPreviouslyFetched) {
        const response = await apiClient.getPolicies();
        const policies = response.result.policies;

        if (policies?.length === 1) {
          setPolicyNumber(policies[0]?.policyNumber ?? "");
        }

        setBeforeYouStartState({
          ...beforeYouStartState,
          policies,
          isCompleted: true,
        });
      }

      navigate(formRouteInfo[FormRoute.ContactDetails].path);
    } catch (ex) {
      const error = ex as ClaimsMotorGlassApiException;
      if (error?.status === HTTP_STATUS_CODE_NOT_FOUND) {
        navigate(ERROR_DONT_HAVE_A_POLICY_PAGE_URL);
      } else {
        const isMemberContactError = error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;
        navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: {
            referrer: location.pathname,
            exception: { request: "GET /policies", status: error.status },
            isMemberContactError: isMemberContactError,
          },
        });
      }
    } finally {
      sessionStorage.setItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW, true.toString());
    }
  };

  const handleRedirect = async (redirectType: RedirectType) => {
    setBackdrop(true);
    setDisabled(true);

    if (redirectType === RedirectType.Next) {
      gtm(event("Next"));
      await redirectToNextPage();
    } else {
      sessionStorage.setItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED, true.toString());
      const eventDescription = redirectType === RedirectType.Register ? "Register now" : "Log in and claim";
      gtm(event(eventDescription));
      await instance.loginRedirect({ redirectUri: process.env.REACT_APP_ADB2C_RETURN_URL, scopes: [] });
    }

    setBackdrop(false);
    setDisabled(false);
  };

  useEffect(() => {
    const policyNumber = sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);
    policyNumber && setPolicyNumber(policyNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We do not want to record page view when it is redirected
  useEffect(() => {
    const loginRegisterRedirectTriggered = !!sessionStorage.getItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED);
    const hasAttemptedToEnterFlow = !!sessionStorage.getItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW);

    if (!loginRegisterRedirectTriggered || hasAttemptedToEnterFlow) {
      gtm(virtualPageView({ url: location.pathname.toLocaleLowerCase() }));
    }
  }, [location]);

  // Redirect to next page automatically if returning from login / linking
  useEffect(() => {
    const loginRegisterRedirectTriggered = !!sessionStorage.getItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED);
    const hasAttemptedToEnterFlow = !!sessionStorage.getItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW);

    const isLoggedInAndLinked = isAuthenticated && hasCrmId;
    const hasSession = sessionStatus === LoadingStatus.Successful;
    const isReturningFromLoginOrLinking = loginRegisterRedirectTriggered && !hasAttemptedToEnterFlow;

    if (isLoggedInAndLinked && hasSession && isReturningFromLoginOrLinking) {
      handleRedirect(RedirectType.Next);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, hasCrmId, sessionStatus]);

  return {
    isAuthenticated,
    disabled,
    hideNcb,
    handleRedirect,
  };
};

export default useBeforeYouStart;
