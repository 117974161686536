import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RequireMemberIsLinked, RequireSsoCheck, RequireSsoCheckProps } from "raci-react-library";
import { UTILITY_REDIRECT_URL } from "../../shared/routing/routes.config";
import { LogCustomProperties } from "../../shared/utils/ApplicationInsights";
import Form from "./Form";
import useBeforeYouStart from "./hooks/useBeforeYouStart";

const LOG_LOCATION = "BeforeYouStart";

const BeforeYouStartForm: React.FC = () => {
  const props = useBeforeYouStart();
  return <Form {...props} />;
};

const BeforeYouStart = () => {
  const appInsights = useAppInsightsContext();

  const onSilentLoginError: RequireSsoCheckProps["onSilentLoginError"] = (error, ssoAttempts) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: ssoAttempts.toString(),
    };

    appInsights.trackTrace({ message: error.message, severityLevel: SeverityLevel.Warning }, customProperties);
  };

  const onSilentLoginSuccess: RequireSsoCheckProps["onSuccess"] = (ssoAttempts, timeTakenInSeconds) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: ssoAttempts.toString(),
      timeTakenInSeconds: timeTakenInSeconds.toFixed(2),
    };

    appInsights.trackTrace(
      {
        message: `SsoSilent successful after ${ssoAttempts} attempts in ${timeTakenInSeconds.toFixed(2)}s`,
        severityLevel: SeverityLevel.Information,
      },
      customProperties,
    );
  };

  const onLoginRedirect: RequireSsoCheckProps["onLoginRedirect"] = (ssoAttempts, timeTakenInSeconds) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: ssoAttempts.toString(),
      timeTakenInSeconds: timeTakenInSeconds.toFixed(2),
    };

    appInsights.trackTrace(
      {
        message: `SsoSilent failed after ${ssoAttempts} attempts in ${timeTakenInSeconds.toFixed(
          2,
        )}s, falling back to interactive login`,
        severityLevel: SeverityLevel.Warning,
      },
      customProperties,
    );
  };

  return (
    <RequireSsoCheck
      ssoSilentRedirectUrl={UTILITY_REDIRECT_URL}
      loginRedirectUrl={process.env.REACT_APP_ADB2C_RETURN_URL ?? ""}
      onSilentLoginError={onSilentLoginError}
      onLoginRedirect={onLoginRedirect}
      onSuccess={onSilentLoginSuccess}
    >
      <RequireMemberIsLinked>
        <BeforeYouStartForm />
      </RequireMemberIsLinked>
    </RequireSsoCheck>
  );
};

export default BeforeYouStart;
