import { PhoneNumber, SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";
import { Repairer } from "./components/RepairerCard";

export interface ConfirmationFormProps extends SessionState {
  form: UseFormReturn<ConfirmationState>;
  variant: ConfirmationVariant;
  claimNumber: string;
  repairer?: RepairerDetails;
  informantFirstName?: string;
  bookingLink?: string;
  showIconWiggle?: boolean;
  skeleton?: ConfirmationSkeleton;
}

export interface ConfirmationSkeleton {
  header: boolean;
  repairerDetails: boolean;
  bookingAndNotification: boolean;
  homepageButton: boolean;
}

export interface ConfirmationState extends SessionState {}

export interface RepairerDetails {
  name: Repairer;
  phoneNumber: PhoneNumber;
}

export enum ConfirmationVariant {
  RepairsNeeded = "RepairsNeeded",
  RepairsBooked = "RepairsBooked",
  RepairsDone = "RepairsDone",
  PaymentBlock = "PaymentBlock",
}
