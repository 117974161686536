import { CreateClaimErrorResponseIneligibleReason } from "raci-claims-motor-glass-clientproxy";
import { useGetSessionState } from "raci-react-library";
import { useEffect, useReducer } from "react";
import { ConfirmationState } from "../../../views/Confirmation/types";
import { StartYourClaimState } from "../../../views/StartYourClaim/types";
import { FormRoute, formRouteInfo } from "../../routing/routes.config";

export interface FlowState {
  isFlowCompleted: boolean;
  flowCompletedUrl: string;
  flowStateChanged?: () => void;
}

export const useFlowState = (): FlowState => {
  const flowChangedEvent = "flowChanged";

  // required as useGetSessionState does not update post render so we need to force update to get updated values
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const startYourClaimState = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const confirmationState = useGetSessionState<ConfirmationState>(FormRoute.Confirmation);

  const updateFlowState = () => {
    return startYourClaimState.error &&
      startYourClaimState.error.ineligibleReason !== CreateClaimErrorResponseIneligibleReason.SimilarClaim
      ? {
          flowCompletedUrl: formRouteInfo[FormRoute.StartYourClaim].path,
          isFlowCompleted: startYourClaimState.isCompleted ?? false,
        }
      : {
          flowCompletedUrl: formRouteInfo[FormRoute.Confirmation].path,
          isFlowCompleted: confirmationState.isCompleted ?? false,
        };
  };

  const { isFlowCompleted, flowCompletedUrl } = updateFlowState();

  const flowStateChanged = () => {
    window.dispatchEvent(new Event(flowChangedEvent));
  };

  useEffect(() => {
    const handleFlowChangedEvent = () => {
      forceUpdate();
    };

    window.addEventListener(flowChangedEvent, handleFlowChangedEvent);

    return () => window.removeEventListener(flowChangedEvent, handleFlowChangedEvent);
  }, []);

  return { flowCompletedUrl, isFlowCompleted, flowStateChanged };
};

export default useFlowState;
