import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";

export const NextStepsCard: React.FC<{ steps: string[] }> = ({ steps }) => (
  <CardWithIcon
    icon={faUserAlt}
    iconSize={"24px"}
    title={<Typography variant="h3">Your next steps</Typography>}
    paragraphs={steps.map((step) => (
      <Typography variant="body1">{step}</Typography>
    ))}
    data-testid="next-steps"
  />
);

export default NextStepsCard;
