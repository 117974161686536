import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { CheckMemberLinked, CheckMemberLinkedProps } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";
import { ADB2C_CLIENT_ID } from "../../constants";
import {
  ERROR_DONT_HAVE_A_POLICY_PAGE_URL,
  ERROR_SESSION_TIMEOUT_PAGE_URL,
  ERROR_SYSTEM_UNAVAILABLE_PAGE_URL,
  UTILITY_REDIRECT_URL,
} from "../../routing/routes.config";
import { LogCustomProperties } from "../../utils/ApplicationInsights";

const LOG_LOCATION = "CheckMemberLinked";

export const CheckMemberLinkedWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appInsights = useAppInsightsContext();

  const disableCheck = [
    ERROR_SYSTEM_UNAVAILABLE_PAGE_URL,
    ERROR_DONT_HAVE_A_POLICY_PAGE_URL,
    ERROR_SESSION_TIMEOUT_PAGE_URL,
    UTILITY_REDIRECT_URL,
  ].includes(location.pathname);

  const onTokenRefreshError: CheckMemberLinkedProps["onTokenRefreshError"] = (error, idTokenRefreshAttempts) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: idTokenRefreshAttempts.toString(),
    };

    appInsights.trackTrace(
      {
        message: error.message,
        severityLevel: SeverityLevel.Warning,
      },
      customProperties,
    );
  };

  const onError: CheckMemberLinkedProps["onError"] = (idTokenRefreshAttempts, timeTakenInSeconds) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: idTokenRefreshAttempts.toString(),
      timeTakenInSeconds: timeTakenInSeconds.toFixed(2),
    };

    const refreshError = new Error(
      `Error refreshing ID token after ${idTokenRefreshAttempts} attempts in ${timeTakenInSeconds.toFixed(2)}s`,
    );

    appInsights.trackException(
      {
        exception: refreshError,
        severityLevel: SeverityLevel.Error,
      },
      customProperties,
    );
    navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const onSuccess: CheckMemberLinkedProps["onSuccess"] = (idTokenRefreshAttempts, timeTakenInSeconds) => {
    const customProperties: LogCustomProperties = {
      location: LOG_LOCATION,
      attempts: idTokenRefreshAttempts.toString(),
      timeTakenInSeconds: timeTakenInSeconds.toFixed(2),
    };

    appInsights.trackTrace(
      {
        message: `Successfully fetched new ID token with CRM-ID after ${idTokenRefreshAttempts} attempts in ${timeTakenInSeconds.toFixed(
          2,
        )}s`,
        severityLevel: SeverityLevel.Information,
      },
      customProperties,
    );
  };

  return (
    <CheckMemberLinked
      clientId={ADB2C_CLIENT_ID}
      productLinkingRedirectUrl={process.env.REACT_APP_PRODUCT_LINKING_REDIRECT_URL ?? ""}
      ssoSilentRedirectUrl={UTILITY_REDIRECT_URL ?? ""}
      disableCheck={disableCheck}
      onTokenRefreshError={onTokenRefreshError}
      onError={onError}
      onSuccess={onSuccess}
    />
  );
};
