import { Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { ErrorPageContent, InsurancePhoneNumberLink } from "raci-react-library";
import { useEffect } from "react";

export const DontHaveAPolicy: React.FC = () => {
  useEffect(() => {
    gtm(event("No active car insurance policies"));
  }, []);

  return (
    <ErrorPageContent
      title="You can't make a claim"
      subtitle="You don't have any active car insurance policies."
      extraContent={
        <Typography>
          If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
          <InsurancePhoneNumberLink id="call-us-link" />.
        </Typography>
      }
    />
  );
};

export default DontHaveAPolicy;
