import { Button, Grid, Typography, styled } from "@mui/material";

export const StyledGridContainer = styled(Grid)({
  width: "min(100%, 600px)",
  "& svg[data-icon='money-bill-wave']": {
    marginTop: "3px",
  },
});

export const StyledSubHeadingContainer = styled(Grid)(({ theme }) => ({
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    marginBottom: "32px",
  },
}));

export const StyledSubHeadingText = styled(Typography)(({ theme }) => ({
  fontsize: "26px",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
}));

export const StyledTitleText = styled(Typography)({
  fontWeight: 400,
  fontSize: "18px",
});

export const StyledRegisterNow = styled(Typography)({
  paddingTop: "8px",
  textAlign: "center",
  fontWeight: 400,
});

export const StyledButton = styled(Button)({
  margin: "24px 0 0 0",
});
