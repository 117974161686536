import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { ClaimNumberDisplay, PolicyPicker } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { ClaimDetailsLockedCard } from "../../shared/components/ClaimDetailsLockedCard";
import PolicyNotListedDialog from "./components/PolicyNotListedDialog";
import { YourPolicyFormProps } from "./types";

export const YourPolicyForm: React.FC<YourPolicyFormProps> = ({
  form,
  onSubmit,
  showPolicyNotListedDialog,
  setShowPolicyNotListedDialog,
  claimNumber,
  submitError,
  initialPolicyNumber,
  policyOptions,
  recordGtmFieldEvent,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  // TODO: replace with <SelectionGroup /> of <SelectionGroup.PolicyCard /> from RRL
  // <PolicyPicker /> does not manange the selected policy with react-hook-form state
  const [policyNumber, setPolicyNumber] = useState(initialPolicyNumber);

  const onSubmitWrapper = () => {
    const values = {
      policyNumber: policyNumber,
    };
    onSubmit(values);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmitWrapper)} action="#">
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Which car was damaged?
            </Typography>
            {claimNumber && (
              <Box sx={{ paddingTop: "8px" }}>
                <ClaimNumberDisplay number={claimNumber} />
              </Box>
            )}
          </Grid>
          <Grid item xs>
            <PolicyPicker
              options={policyOptions}
              value={policyNumber}
              onChange={(value: string) => {
                !claimNumber && setPolicyNumber(value);
                recordGtmFieldEvent(policyOptions.filter((x) => x.policyNumber === value)[0].policySubtitle);
              }}
              helperText="Please select an option"
              error={submitError && !policyNumber}
            />
          </Grid>
          <Grid item xs>
            {claimNumber ? (
              <ClaimDetailsLockedCard />
            ) : (
              <Link
                style={{ cursor: "pointer" }}
                variant="h4"
                onClick={() => {
                  gtm(event("Car insurance policy not listed?"));
                  setShowPolicyNotListedDialog(true);
                }}
              >
                Car insurance policy not listed?
              </Link>
            )}
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              id="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <PolicyNotListedDialog showDialog={showPolicyNotListedDialog} setShowDialog={setShowPolicyNotListedDialog} />
      </form>
    </FormProvider>
  );
};

export default YourPolicyForm;
