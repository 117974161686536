import { Grid } from "@mui/material";
import { BASE_PDS_URL, StartClaim } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { ClaimDetailsLockedCard } from "../../shared/components/ClaimDetailsLockedCard";
import { StyledCallToAction } from "../styled";
import IneligibleForClaimError from "./components/IneligibleForClaimError";
import SimilarClaimDialog from "./components/SimilarClaimDialog";
import { StartYourClaimFormProps } from "./types";

export const StartYourClaimForm: React.FC<StartYourClaimFormProps> = ({
  form,
  onSubmit,
  policyDetails,
  error,
  disabled,
  claimNumber,
  showSimilarClaimDialog,
  onSimilarClaimAllowed,
  showClaimDetailsLockedCard,
}) => {
  const nextButtonId = "submit-button";
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        {policyDetails && (
          <Grid container spacing={1}>
            <StartClaim
              policy={{
                policyNumber: policyDetails?.policyNumber ?? "",
                policyTitle: `${policyDetails?.year} ${policyDetails?.make}`,
                policySubtitle: policyDetails?.registrationNumber,
              }}
              pds={BASE_PDS_URL}
              disabled={disabled}
              claimNumber={claimNumber}
            >
              <>
                {error && (
                  <IneligibleForClaimError
                    reason={error.ineligibleReason}
                    duplicateClaimNumber={error.duplicateOrSimilarClaimNumber}
                  />
                )}
                {showClaimDetailsLockedCard && <ClaimDetailsLockedCard />}
                <StyledCallToAction
                  type="submit"
                  id={nextButtonId}
                  data-testid={nextButtonId}
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={(disabled && !claimNumber) || isSubmitting}
                >
                  Next
                </StyledCallToAction>
              </>
            </StartClaim>
          </Grid>
        )}

        <SimilarClaimDialog
          showDialog={showSimilarClaimDialog}
          eventDate={error?.similarClaimEventDate ?? new Date().toISOString()}
          claimNumber={error?.duplicateOrSimilarClaimNumber}
          onButtonClick={handleSubmit(onSimilarClaimAllowed)}
        />
      </form>
    </FormProvider>
  );
};

export default StartYourClaimForm;
