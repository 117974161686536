import { ClaimsMotorGlassApiException } from "raci-claims-motor-glass-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BackdropMessages } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, FormRoute, formRouteInfo } from "../../../../shared/routing/routes.config";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourGlassRepairsState } from "../../../YourGlassRepairs/types";
import { ReviewYourClaimFormProps, ReviewYourClaimState } from "../../types";

export const useReviewYourClaim = (): ReviewYourClaimFormProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const [currentReviewYourClaimState, setReviewYourClaimState] = useSessionState<ReviewYourClaimState>();
  const currentYourGlassRepairsState = useGetSessionState<YourGlassRepairsState>(FormRoute.YourGlassRepairs);
  const currentStartYourClaimState = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);

  // Check state values for the page
  // We don't want to load the page if the states values do not exist
  if (
    !currentStartYourClaimState.policyDetails ||
    !currentStartYourClaimState.claimNumber ||
    !currentStartYourClaimState.date ||
    !currentYourGlassRepairsState.glassRepairOption
  ) {
    navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
      state: { referrer: location.pathname },
    });
  }

  const onSubmit = async () => {
    try {
      setBackdrop(true, BackdropMessages.ProcessingYourClaim);
      const response = await apiClient.allocateServiceProvider();
      setReviewYourClaimState({ ...response.result, isCompleted: true });
      navigate(formRouteInfo[FormRoute.Confirmation].path);
    } catch (exception) {
      const error = exception as ClaimsMotorGlassApiException;
      navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
        state: {
          referrer: location.pathname,
          exception: { request: "POST /claims/service-provider", status: error.status },
        },
      });
    } finally {
      setBackdrop(false, BackdropMessages.ProcessingYourClaim);
    }
  };

  const form = useForm<ReviewYourClaimState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: currentReviewYourClaimState,
  });

  return {
    form,
    onSubmit,
    policyDetails: currentStartYourClaimState.policyDetails,
    claimNumber: currentStartYourClaimState.claimNumber ?? "",
    claimStartDate: currentStartYourClaimState.date,
    glassRepairOption: currentYourGlassRepairsState.glassRepairOption,
    glassDamageOption: currentYourGlassRepairsState.glassDamageOptions,
  };
};

export default useReviewYourClaim;
