import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@mui/material";
import { ClaimNumberDisplay } from "raci-react-library";
import { SkeletonOrComponent } from "../../../../shared/components/SkeletonOrComponent";
import { StyledFadeWiggleIcon, StyledIcon, StyledSubtitle, StyledTitle } from "./styled";

export interface ConfirmationHeaderProps {
  title: string;
  subtitle: string;
  claimNumber: string;
  showIconWiggle?: boolean;
  showSkeleton?: boolean;
}

export const ConfirmationHeader: React.FC<ConfirmationHeaderProps> = ({
  title,
  subtitle,
  claimNumber,
  showIconWiggle = true,
  showSkeleton = false,
}) => {
  return (
    <Grid container direction="column" sx={{ gap: "16px" }}>
      <Grid item xs>
        <SkeletonOrComponent
          showSkeleton={showSkeleton}
          sx={{ mx: "auto" }}
          animation="wave"
          variant="circular"
          data-testid="confirmation-header-icon-skeleton"
        >
          {showIconWiggle ? (
            <StyledFadeWiggleIcon icon={faThumbsUp} size="7x" />
          ) : (
            <StyledIcon icon={faThumbsUp} size="7x" />
          )}
        </SkeletonOrComponent>
      </Grid>
      <SkeletonOrComponent
        showSkeleton={showSkeleton}
        sx={{ mx: "auto", borderRadius: "3px" }}
        animation="wave"
        variant="rectangular"
        data-testid="confirmation-header-text-skeleton"
      >
        <Grid item xs>
          <StyledTitle id="confirmation-header-title" data-testid="confirmation-header-title" variant="h1">
            {title}
          </StyledTitle>
        </Grid>
        <Grid item xs>
          <StyledSubtitle id="confirmation-header-subtitle" data-testid="confirmation-header-subtitle">
            {subtitle}
          </StyledSubtitle>
        </Grid>
        <Grid item xs>
          <ClaimNumberDisplay number={claimNumber} />
        </Grid>
      </SkeletonOrComponent>
    </Grid>
  );
};

export default ConfirmationHeader;
