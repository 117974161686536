import { useSessionStateInitialiser } from "raci-react-library";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { STORAGE_KEY_POLICY_NUMBER } from "../../constants";
import { useFlowState } from "../../hooks/useFlowState";
import { FormRoute, RouteInformation, formRoutes as formRoutesArray, preFormRoutes } from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  steps: StepInformation[];
  isActiveFlow: boolean;
  activeStepIndex?: number;
  totalStepCount?: number;
  navigateToPreviousStep: () => void;
  formRoutes: RouteInformation[];
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  const { isFlowCompleted } = useFlowState();

  const enteredFlowWithSinglePolicy = !!sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);

  const formRoutes = useMemo(() => {
    let availableRoutes = [...formRoutesArray];

    if (enteredFlowWithSinglePolicy) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.YourPolicy);
    }

    return availableRoutes;
  }, [enteredFlowWithSinglePolicy]);

  useEffect(() => {
    initialiseSessionStates(
      [...preFormRoutes, ...formRoutesArray].map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const isActiveFlow = formRoutes.some((item) => item.path === location.pathname);

  const steps: StepInformation[] = useMemo(() => {
    if (!isActiveFlow) return [];

    return formRoutes.reduce((filtered: StepInformation[], option) => {
      filtered.push({ name: option.name, path: option.path });
      return filtered;
    }, []);
  }, [formRoutes, isActiveFlow]);

  const activeStepIndex = steps.findIndex((item) => item.path === location.pathname);

  const totalStepCount = isActiveFlow ? steps.length : undefined;

  const navigateToPreviousStep = useMemo(
    () => () => isActiveFlow && activeStepIndex > 0 && navigate(steps[activeStepIndex - 1].path, { replace: true }),
    [isActiveFlow, activeStepIndex, navigate, steps],
  );

  const canNavigateToPreviousStep = useMemo(() => {
    return !!navigateToPreviousStep && activeStepIndex !== 0 && !isFlowCompleted;
  }, [navigateToPreviousStep, activeStepIndex, isFlowCompleted]);

  return {
    steps,
    isActiveFlow,
    activeStepIndex,
    totalStepCount,
    formRoutes,
    navigateToPreviousStep,
    canNavigateToPreviousStep,
  };
};

export default useRoutes;
