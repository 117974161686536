import { Card, styled } from "@mui/material";

type StyledImgProps = {
  repairerName: string;
};

export const StyledCard = styled(Card)({
  margin: 0,
  padding: "24px",
  lineHeight: "inherit",
  background: "white",
});

export const StyledImg = styled("img", { shouldForwardProp: (prop) => prop !== "repairerName" })<StyledImgProps>(
  ({ repairerName }) => ({
    maxWidth: "100%",
    maxHeight: "100%",
    ...(repairerName === "Instant Windscreens" && {
      width: "200px",
    }),
    ...((repairerName === "O'Brien Autoglass" || repairerName === "Novus Autoglass") && {
      width: "160px",
    }),
  }),
);
