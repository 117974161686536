import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFlowState from "../../hooks/useFlowState";

export interface RouteGuardProps {
  disableChecks?: string[];
  children?: React.ReactNode;
}

export const RouteGuard: React.FC<RouteGuardProps> = ({ disableChecks = [], children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();
  const { flowCompletedUrl, isFlowCompleted } = useFlowState();

  /**
   * The below will check if the current page can
   * be navigated to by checking if the previous
   * page has been completed.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
    if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
      navigate(previousPageUrl, { replace: true });
    }
  }, [navigate, disableChecks, previousPageUrl, isPreviousPageCompleted, location.pathname]);

  /**
   * Forces the user to a particular page, depending on their current flow
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();

    // If the user has completed their claim, and tries to transition to a previous page
    if (isFlowCompleted && currentLocation !== flowCompletedUrl) {
      navigate(flowCompletedUrl, { replace: true });
    }
  }, [navigate, isFlowCompleted, flowCompletedUrl, location.pathname]);

  return <>{children}</>;
};

export default RouteGuard;
