import { Grid, styled, Typography } from "@mui/material";
import { colors } from "@racwa/styles";

export const StyledSummaryContainer = styled("div")({
  marginLeft: "0px",
  marginRight: "0px",
  marginTop: "16px",
  backgroundColor: "white",
  padding: "16px",
  paddingTop: "0px",
  border: "1px solid",
  borderRadius: "3px",
  borderColor: colors.dieselLight,
});

export const StyledSummaryContainerHeader = styled(Typography)({
  paddingTop: "16px",
  paddingBottom: "16px",
});

export const StyledGridItemPaddingFirst = styled(Grid)({
  paddingTop: "8px",
  paddingBottom: "8px",
});

export const StyledGridItemPadding = styled(Grid)({
  paddingTop: "16px",
  paddingBottom: "16px",
});

export const StyledGlassDamageText = styled(Grid)(({ theme }) => ({
  marginTop: "24px",
  textAlign: "left",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
