export interface GlassOption {
  id: string;
  display: string;
}

export const GlassOptionRepaired: GlassOption = {
  id: "glassRepaired",
  display: "Yes, it's fixed",
};

export const GlassOptionRepairBooked: GlassOption = {
  id: "glassRepairBooked",
  display: "I've booked the repairs",
};

export const GlassOptionNotRepaired: GlassOption = {
  id: "glassNotRepaired",
  display: "No",
};

export const GlassOptions: GlassOption[] = [GlassOptionRepaired, GlassOptionRepairBooked, GlassOptionNotRepaired];
