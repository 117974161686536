import { faMoneyBillWave, faPiggyBank, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Grid, Link, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { CardWithIcon } from "raci-react-library";
import {
  StyledButton,
  StyledGridContainer,
  StyledRegisterNow,
  StyledSubHeadingContainer,
  StyledSubHeadingText,
  StyledTitleText,
} from "./styled";
import { BeforeYouStartFormProps, RedirectType } from "./types";

const cardInfo = [
  { icon: faThumbsUp, title: "This claim won't affect your no claim bonus." },
  { icon: faPiggyBank, title: "There's no excess for chip repairs." },
  { icon: faMoneyBillWave, title: "Excess is $100 for replacements.*" },
];

const cardInfoWithHideNcb = [
  { icon: faPiggyBank, title: "There's no excess for chip repairs." },
  { icon: faMoneyBillWave, title: "There's an excess for replacements." },
];

export const BeforeYouStartForm = ({ isAuthenticated, disabled, hideNcb, handleRedirect }: BeforeYouStartFormProps) => {
  const eventLoginClaimRegister = "Log in and claim or Register now";
  const filteredCardInfo = hideNcb ? cardInfoWithHideNcb : [...cardInfo];

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <StyledSubHeadingContainer item xs={12}>
        <StyledSubHeadingText variant="h2">Before you start</StyledSubHeadingText>
      </StyledSubHeadingContainer>

      <StyledGridContainer container item spacing={1} justifyContent="center">
        {filteredCardInfo.map((card) => (
          <Grid item xs={12} key={card.title}>
            <CardWithIcon
              icon={card.icon}
              title={<StyledTitleText>{card.title}</StyledTitleText>}
              iconSize="20px !important"
              spacing={2}
            />
          </Grid>
        ))}
        {!hideNcb && (
          <Grid item xs={12}>
            <Typography align="center" variant="body2">
              *Unless you've paid extra and have no excess.
            </Typography>
          </Grid>
        )}
        {isAuthenticated ? (
          <Grid item xs={12}>
            <StyledButton
              type="button"
              color="primary"
              variant="contained"
              fullWidth={true}
              onClick={() => handleRedirect(RedirectType.Next)}
              disabled={disabled}
              data-testid="next"
            >
              Next
            </StyledButton>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <StyledButton
                type="button"
                color="primary"
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  gtm(fieldTouched(eventLoginClaimRegister));
                  handleRedirect(RedirectType.Login);
                }}
                disabled={disabled}
                data-testid="login-register"
              >
                Log in and claim
              </StyledButton>
            </Grid>
            <Grid item xs={12}>
              <StyledRegisterNow>
                Don't have an account?{" "}
                <Link
                  href="#"
                  rel="noreferrer noopener"
                  onClick={() => {
                    gtm(fieldTouched(eventLoginClaimRegister));
                    handleRedirect(RedirectType.Register);
                  }}
                >
                  Register now
                </Link>
              </StyledRegisterNow>
            </Grid>
          </>
        )}
      </StyledGridContainer>
    </Grid>
  );
};

export default BeforeYouStartForm;
