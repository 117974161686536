import { Skeleton, SkeletonProps } from "@mui/material";

export interface SkeletonOrComponentProps extends SkeletonProps {
  showSkeleton: boolean;
}

export const SkeletonOrComponent: React.FC<SkeletonOrComponentProps> = ({
  showSkeleton,
  children,
  ...skeletonProps
}) => (showSkeleton ? <Skeleton {...skeletonProps}>{children}</Skeleton> : <>{children}</>);

export default SkeletonOrComponent;
