import { Button, Grid, useMediaQuery } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { HTTP_QUERY_STRING_CLAIM_NUMBER } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { SkeletonOrComponent } from "../../shared/components/SkeletonOrComponent";
import ConfirmationHeader from "./components/ConfirmationHeader";
import NextStepsCard from "./components/NextStepsCard";
import { RepairerCard } from "./components/RepairerCard";
import { StyledContainer } from "./styled";
import { ConfirmationFormProps, ConfirmationVariant } from "./types";

const getConfirmationTitleWithName = (name?: string) => `Claim received - over to you${name ? `, ${name}` : ""}!`;

interface ConfirmationVariantInformation {
  title: (name?: string) => string;
  subtitle: string;
  nextSteps?: string[];
}

const ConfirmationVariantInfo: Record<ConfirmationVariant, ConfirmationVariantInformation> = {
  [ConfirmationVariant.RepairsNeeded]: {
    title: () => "Claim received!",
    subtitle: "You'll receive a confirmation email shortly.",
  },
  [ConfirmationVariant.RepairsBooked]: {
    title: getConfirmationTitleWithName,
    subtitle: "You'll receive an email shortly.",
    nextSteps: [
      "Please give the repairer your claim number.",
      "The email will have more information on what to do next.",
    ],
  },
  [ConfirmationVariant.RepairsDone]: {
    title: getConfirmationTitleWithName,
    subtitle: "You'll receive an email shortly.",
    nextSteps: [
      "Please submit your invoice for us to review.",
      "Or you can do this later. The email will have a link.",
    ],
  },
  [ConfirmationVariant.PaymentBlock]: {
    title: () => "We've got your claim, thanks!",
    subtitle: "We'll review your claim and get back to you within one business day.",
  },
};

const getUploadInvoiceUrl = (claimNumber: string) => {
  const url = `${process.env.REACT_APP_SPARK_CLAIMS_SERVICING_UPLOAD_INVOICE_URL}?${HTTP_QUERY_STRING_CLAIM_NUMBER}=${claimNumber}`;
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { BypassService } = require("raci-react-library-npe");
    return BypassService.recreateUrlFromSession(url);
  } else {
    return url;
  }
};

export const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  form,
  variant,
  claimNumber,
  repairer,
  informantFirstName,
  bookingLink,
  showIconWiggle,
  skeleton,
}) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const title = ConfirmationVariantInfo[`${variant}`].title(informantFirstName);
  const subtitle = ConfirmationVariantInfo[`${variant}`].subtitle;
  const nextSteps = ConfirmationVariantInfo[`${variant}`].nextSteps;

  return (
    <FormProvider {...form}>
      <form action="#">
        <StyledContainer container direction="column">
          <Grid item xs>
            <ConfirmationHeader
              title={title}
              subtitle={subtitle}
              claimNumber={claimNumber}
              showIconWiggle={showIconWiggle}
              showSkeleton={skeleton?.header}
            />
          </Grid>
          {variant === ConfirmationVariant.RepairsNeeded && repairer && (
            <Grid item xs>
              <RepairerCard
                {...repairer}
                bookingLink={bookingLink}
                showSkeletonDetails={skeleton?.repairerDetails}
                showSkeletonBookingAndNotification={skeleton?.bookingAndNotification}
              />
            </Grid>
          )}
          {nextSteps && <NextStepsCard steps={nextSteps} />}
          <SkeletonOrComponent
            sx={{ borderRadius: "3px" }}
            data-testid="rac-home-page-button-skeleton"
            variant="rectangular"
            animation="wave"
            width="100%"
            showSkeleton={skeleton?.homepageButton ?? false}
          >
            <StyledContainer container direction={isMobileView ? "column" : "row"}>
              {variant === ConfirmationVariant.RepairsDone && (
                <Grid item xs>
                  <Button
                    href={getUploadInvoiceUrl(claimNumber)}
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="button"
                    role="button"
                    id={`invoice-upload-link-button`}
                    data-testid={`invoice-upload-link-button`}
                    onClick={() => gtm(event("Submit invoice now"))}
                  >
                    Submit invoice now
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  type="button"
                  role="button"
                  href={process.env.REACT_APP_RAC_HOME_PAGE}
                  id={`rac-home-page-link-button`}
                  data-testid={`rac-home-page-link-button`}
                  color={bookingLink || variant === ConfirmationVariant.RepairsDone ? "secondary" : "primary"}
                >
                  Go to RAC homepage
                </Button>
              </Grid>
            </StyledContainer>
          </SkeletonOrComponent>
        </StyledContainer>
      </form>
    </FormProvider>
  );
};

export default ConfirmationForm;
