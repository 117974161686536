import { Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaCardNotification } from "@racwa/react-components";
import { ClaimNumberDisplay, MultiChoiceInput, MultiChoiceOption, RadioButtonGroup } from "raci-react-library";
import { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import {
  GlassOptionNotRepaired,
  GlassOptionRepairBooked,
  GlassOptionRepaired,
} from "../../shared/constants/GlassOptions";
import { StyledCallToAction } from "../styled";
import { YourGlassRepairsFormProps } from "./types";

export const YourGlassRepairsForm: React.FC<YourGlassRepairsFormProps> = ({
  form,
  onSubmit,
  glassRepairOptions,
  glassDamageOptions,
  defaultDamageOption,
  claimNumber,
  recordGlassRepairOptionEvent,
}) => {
  const {
    control,
    setValue,
    resetField,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = form;
  const glassRepairOptionsId = "glassRepairOption";
  const glassDamageOptionsId = "glassDamageOptions";
  const nextButtonId = "submit-button";
  const glassRepairOptionsWatch = useWatch({ control, name: glassRepairOptionsId });
  const glassDamageOptionsWatch = useWatch({ control, name: glassDamageOptionsId });
  const [recordDamageOptionEvent, setRecordDamageOptionEvent] = useState(false);

  const showGlassDamageOptions = glassRepairOptionsWatch === GlassOptionNotRepaired.id;
  const fixedOrBookedRepairs =
    glassRepairOptionsWatch === GlassOptionRepairBooked.id || glassRepairOptionsWatch === GlassOptionRepaired.id;
  const hasSelectedGlassDamageOption = glassDamageOptionsWatch && glassDamageOptionsWatch?.length > 0;
  const showInformationDialog = fixedOrBookedRepairs || hasSelectedGlassDamageOption;

  const getInformationDialogTitle = (fixedOrBookedRepairs: boolean) => {
    return fixedOrBookedRepairs ? "Great to hear" : "Time to get your glass fixed!";
  };

  const getInformationDialogContent = (
    glassFixOptionsWatch: string | undefined,
    hasSelectedGlassDamageOption: boolean | undefined,
  ) => {
    if (glassFixOptionsWatch === GlassOptionRepaired.id) {
      return "All you need to do is submit your claim and then your repair invoice.";
    }

    if (glassFixOptionsWatch === GlassOptionRepairBooked.id) {
      return "All you need to do is give your repairer the claim number then submit your invoice to us.";
    }

    if (hasSelectedGlassDamageOption) {
      return "Our repairers provide services Australia-wide.";
    }

    return undefined;
  };

  // Note: This function synchronises the checkboxes when clicked multiple times.
  // There is a case when using backwards and forwards navigation, where the watched value will increase when checking the box again
  // So each time a box is checked, we want to filter out any duplicates that may have appeared on the form.
  const synchroniseDamageOptions = (option: MultiChoiceOption, checked: boolean) => {
    const selectedDamageOptions = getValues(glassDamageOptionsId) ?? [];
    if (!checked) {
      const newSelectedDamageOptions = selectedDamageOptions?.filter((item) => item.key !== option.key);
      setValue(glassDamageOptionsId, newSelectedDamageOptions);
    } else {
      const newSelectedDamageOptions = selectedDamageOptions?.filter((item) => item.key !== option.key);
      newSelectedDamageOptions?.push(option);
      setValue(glassDamageOptionsId, newSelectedDamageOptions);
    }
  };

  const recordDamageOptionsEvent = () => {
    if (!recordDamageOptionEvent && (!glassDamageOptionsWatch || glassDamageOptionsWatch.length === 0)) {
      setRecordDamageOptionEvent(true);
      gtm(event("Please select an option"));
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Your glass repairs
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ClaimNumberDisplay number={claimNumber} />
          </Grid>
          <Grid item xs={12}>
            <RadioButtonGroup
              id={glassRepairOptionsId}
              name={glassRepairOptionsId}
              data-testid={glassRepairOptionsId}
              label="Has your glass already been fixed?"
              sublabel="Please choose one option."
              items={glassRepairOptions}
              errorLabel="Please select an option"
              fullwidth
              onChange={(event, value) => {
                resetField(glassDamageOptionsId);
                setRecordDamageOptionEvent(false);
                gtm(fieldTouched("Has your glass already been fixed?"));
                recordGlassRepairOptionEvent(value);
              }}
            />
          </Grid>
          {showGlassDamageOptions && (
            <Grid item xs={12}>
              <MultiChoiceInput
                name={glassDamageOptionsId}
                data-testid={glassDamageOptionsId}
                label="What glass was damaged?"
                sublabel="You can choose more than one option."
                options={glassDamageOptions}
                errorLabel="Please select an option"
                optionsFullWidth
                defaultValue={defaultDamageOption}
                onSelectionChange={(item, checked) => {
                  synchroniseDamageOptions(item, checked);
                  gtm(fieldTouched("What glass was damaged?"));
                  gtm(event(item.label));
                  setRecordDamageOptionEvent(false);
                }}
              />
            </Grid>
          )}
          {showInformationDialog && (
            <Grid item xs={12}>
              <RacwaCardNotification severity="info" title={getInformationDialogTitle(fixedOrBookedRepairs)}>
                {getInformationDialogContent(glassRepairOptionsWatch, hasSelectedGlassDamageOption)}
              </RacwaCardNotification>
            </Grid>
          )}
          <Grid item xs={12}>
            <StyledCallToAction
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              onClick={recordDamageOptionsEvent}
              disabled={isSubmitting}
            >
              Next
            </StyledCallToAction>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default YourGlassRepairsForm;
