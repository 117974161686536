import { event, gtm } from "@racwa/analytics";
import { useGetSessionState, useSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  GlassOptionNotRepaired,
  GlassOptionRepairBooked,
  GlassOptionRepaired,
} from "../../../../shared/constants/GlassOptions";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useFlowState from "../../../../shared/hooks/useFlowState";
import { FormRoute } from "../../../../shared/routing/routes.config";
import toPhoneNumber from "../../../../shared/utils/toPhoneNumber";
import { ReviewYourClaimState } from "../../../ReviewYourClaim/types";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourGlassRepairsState } from "../../../YourGlassRepairs/types";
import { ConfirmationFormProps, ConfirmationSkeleton, ConfirmationState, ConfirmationVariant } from "../../types";

function wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const useConfirmation: () => ConfirmationFormProps = () => {
  const currentYourGlassRepairsState = useGetSessionState<YourGlassRepairsState>(FormRoute.YourGlassRepairs);
  const currentReviewYourClaimState = useGetSessionState<ReviewYourClaimState>(FormRoute.ReviewYourClaim);
  const currentStartYourClaimState = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const [confirmationState, setConfirmationState] = useSessionState<ConfirmationState>();
  const { flowStateChanged } = useFlowState();
  const apiClient = useApiClient();
  const [skeleton, setSkeleton] = useState<ConfirmationSkeleton>();
  const [anySkeletonDisplayed, setAnySkeletonDisplayed] = useState(false);
  const isOBrien = currentReviewYourClaimState.repairer === "O'Brien Autoglass";
  const [bookingLink, setBookingLink] = useState<string>();

  const getConfirmationVariant = (glassRepairOption?: string) => {
    if (currentReviewYourClaimState.isBusinessError) {
      return ConfirmationVariant.PaymentBlock;
    }

    switch (glassRepairOption) {
      case GlassOptionNotRepaired.id:
        return ConfirmationVariant.RepairsNeeded;
      case GlassOptionRepairBooked.id:
        return ConfirmationVariant.RepairsBooked;
      case GlassOptionRepaired.id:
        return ConfirmationVariant.RepairsDone;
    }
  };

  useEffect(() => {
    let gtmEvent = "";
    const repairer = currentReviewYourClaimState.repairer;
    const confirmationVariant = getConfirmationVariant(currentYourGlassRepairsState.glassRepairOption);

    if (confirmationVariant === ConfirmationVariant.RepairsNeeded) {
      gtmEvent = `Not repaired - ${repairer}`;
    } else if (confirmationVariant === ConfirmationVariant.RepairsBooked) {
      gtmEvent = "Organised";
    } else if (confirmationVariant === ConfirmationVariant.RepairsDone) {
      gtmEvent = "Repaired";
    } else {
      gtmEvent = "Payment block";
    }

    gtm(event(gtmEvent));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allSkeletonDisplay = {
    header: true,
    repairerDetails: true,
    bookingAndNotification: true,
    homepageButton: true,
  };
  const noSkeletonDisplay = {
    header: false,
    repairerDetails: false,
    bookingAndNotification: false,
    homepageButton: false,
  };

  const fakeRevealPage = (finaliseComplete: boolean) => {
    setAnySkeletonDisplayed(true);
    setSkeleton(allSkeletonDisplay);
    wait(1000)
      .then(() => {
        if (!finaliseComplete) {
          setSkeleton({ ...allSkeletonDisplay, header: false });
          return wait(1000);
        }
      })
      .then(() => {
        if (!finaliseComplete) {
          setSkeleton({ ...allSkeletonDisplay, header: false, repairerDetails: false });
        }
      });
  };

  useEffect(() => {
    if (!confirmationState.isCompleted) {
      try {
        let finaliseComplete = false;

        apiClient.finaliseClaim().then((response) => {
          finaliseComplete = true;
          setBookingLink(response.result.repairerBookingUrl);
          setSkeleton(noSkeletonDisplay);
        });

        if (isOBrien) {
          fakeRevealPage(finaliseComplete);
        }
      } catch {
        // We don't care about failures here, alerting is set for lodgement api failures
        // and referral emails are sent for auto lodgement failing
      }
    }

    setConfirmationState({ isCompleted: true });
    flowStateChanged && flowStateChanged();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<ConfirmationState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  return {
    form,
    variant: getConfirmationVariant(currentYourGlassRepairsState.glassRepairOption),
    claimNumber: currentStartYourClaimState.claimNumber,
    repairer: {
      name: currentReviewYourClaimState.repairer,
      phoneNumber: toPhoneNumber(currentReviewYourClaimState.repairerPhoneNumber ?? ""),
    },
    informantFirstName: currentReviewYourClaimState.informantFirstName,
    skeleton: skeleton,
    showIconWiggle: !anySkeletonDisplayed,
    bookingLink: bookingLink,
  } as ConfirmationFormProps;
};

export default useConfirmation;
