import Typography from "@mui/material/Typography";
import { event, gtm } from "@racwa/analytics";
import { InsurancePhoneNumberLink, NotificationCard } from "raci-react-library";
import { useEffect } from "react";

export const ClaimDetailsLockedCard: React.FC = () => {
  useEffect(() => {
    gtm(event("Sorry, you can't change this once your claim is underway"));
  }, []);

  return (
    <NotificationCard
      severity="info"
      message={{
        title: "Sorry, you can't change this once your claim is underway",
        content: (
          <Typography>
            If you've made a mistake, please call us on <InsurancePhoneNumberLink id="insurance-phone-number-link" />.
          </Typography>
        ),
      }}
    />
  );
};

export default ClaimDetailsLockedCard;
