import { Grid, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { RacwaDetailedDialog } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { useEffect } from "react";

const id = "policy-not-listed-dialog";

export interface PolicyNotListedDialogProps {
  showDialog: boolean;
  setShowDialog: (open: boolean) => void;
}

export const PolicyNotListedDialog: React.FC<PolicyNotListedDialogProps> = ({ showDialog, setShowDialog }) => {
  useEffect(() => {
    if (showDialog) {
      gtm(event("Policy not listed"));
    }
  }, [showDialog]);

  return (
    <RacwaDetailedDialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      onClickClose={() => setShowDialog(false)}
      title="Policy not listed"
      titleId={`${id}-title`}
      id={id}
    >
      <Grid container spacing={2} data-testid={`${id}-content`}>
        <Grid item xs={12}>
          <Typography>Your car insurance policy may not be listed because it's no longer active.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
            <InsurancePhoneNumberLink id={`${id}-phone-number-link`} />.
          </Typography>
        </Grid>
      </Grid>
    </RacwaDetailedDialog>
  );
};

export default PolicyNotListedDialog;
