import { Box, Button, Typography } from "@mui/material";
import { theme } from "@racwa/react-components";
import {
  ErrorContentPhoneButton,
  SystemUnavailableContent,
  trackSystemUnavailablePage,
  useGetSessionState,
} from "raci-react-library";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormRoute } from "../../shared/routing/routes.config";
import { StartYourClaimState } from "../StartYourClaim/types";

export const SystemUnavailable: React.FC = () => {
  const location = useLocation();
  const { claimNumber } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const isMemberContactError = location.state?.isMemberContactError ?? false;

  useEffect(() => {
    trackSystemUnavailablePage(
      location.pathname.toLocaleLowerCase(),
      location.state?.referrer ?? document.referrer,
      location.state?.exception ?? null,
    );
  }, [location]);

  const claimNumberLine = claimNumber ? (
    <Typography>
      Your claim number is{" "}
      <Box component="span" fontWeight="600" color={theme.palette.secondary.light}>
        {claimNumber}
      </Box>
    </Typography>
  ) : undefined;

  const buttons = claimNumber
    ? [ErrorContentPhoneButton]
    : [
        <Button
          type="button"
          role="button"
          id="btn-make-a-claim"
          data-testid="btn-make-a-claim"
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_SPARK_TRIAGE_CAR_INSURANCE_URL}
        >
          Make a car insurance claim
        </Button>,
      ].concat([ErrorContentPhoneButton]);

  return isMemberContactError ? (
    <SystemUnavailableContent
      subtitle={"We couldn't complete your request online. Please call us."}
      buttons={[ErrorContentPhoneButton]}
    />
  ) : (
    <SystemUnavailableContent
      subtitle={
        claimNumber
          ? "Please call us to continue your claim."
          : "We couldn't take you to your destination. Please try again later or call us."
      }
      buttons={buttons}
      claimNumber={claimNumber}
      startClaimAgainUrl={process.env.REACT_APP_SPARK_TRIAGE_CAR_INSURANCE_URL ?? "#"}
      extraContent={claimNumberLine}
      onStartClaimAgain={() => {
        sessionStorage.clear();
      }}
    />
  );
};

export default SystemUnavailable;
