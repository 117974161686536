import { Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { CreateClaimErrorResponseIneligibleReason } from "raci-claims-motor-glass-clientproxy";
import { InsurancePhoneNumber, NotificationCard, PhoneNumberLink } from "raci-react-library";
import { useEffect } from "react";

export interface IneligibleForClaimErrorProps {
  reason?: CreateClaimErrorResponseIneligibleReason;
  duplicateClaimNumber?: string;
}

export const IneligibleForClaimError: React.FC<IneligibleForClaimErrorProps> = ({ reason, duplicateClaimNumber }) => {
  useEffect(() => {
    if (reason === CreateClaimErrorResponseIneligibleReason.PolicyNotActive) {
      gtm(event("Unfortunately, your policy wasn't active on this date"));
    } else if (reason === CreateClaimErrorResponseIneligibleReason.DuplicateClaim) {
      gtm(event("This policy has an existing motor glass claim for this date"));
    } else if (reason === CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForMotorGlass) {
      gtm(event("Unfortunately, the cover you had on this date doesn't include motor glass damage"));
    }
  }, [reason]);

  switch (reason) {
    case CreateClaimErrorResponseIneligibleReason.PolicyNotActive:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Unfortunately, your policy wasn't active on this date",
            content: (
              <Typography>
                If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id={"insurance-phone-number-link"} />.
              </Typography>
            ),
          }}
        />
      );
    case CreateClaimErrorResponseIneligibleReason.DuplicateClaim:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "This policy has an existing motor glass claim for this date ",
            content: (
              <Typography>
                To continue this claim, please call{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id={"insurance-phone-number-link"} /> and quote the
                previous <strong>claim number {duplicateClaimNumber}</strong>.
              </Typography>
            ),
          }}
        />
      );
    case CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForMotorGlass:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Unfortunately, the cover you had on this date doesn't include motor glass damage",
            content: (
              <Typography>
                If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id={"insurance-phone-number-link"} />.
              </Typography>
            ),
          }}
        />
      );
    default:
      return null;
  }
};

export default IneligibleForClaimError;
