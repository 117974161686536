import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  CaptureQueryParameter,
  LoginStatusProvider,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  TrackPageChanges,
  WebChat,
  useSetBackdrop,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { CheckMemberLinkedWrapper } from "./shared/components/CheckMemberLinkedWrapper";
import {
  ADB2C_CLIENT_ID,
  ADB2C_DOMAIN,
  ADB2C_TENANT,
  SESSION_KEY_PREFIX,
  STORAGE_KEY_POLICY_NUMBER,
} from "./shared/constants";
import useCheckLoginStatus from "./shared/hooks/useCheckLoginStatus";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, PRE_FORM_BEFORE_YOU_START_URL } from "./shared/routing/routes.config";
import { reactPlugin } from "./shared/utils/ApplicationInsights";
import Views from "./views";

export const AppInternal: React.FC = () => {
  const setBackdrop = useSetBackdrop();

  return (
    <RacwaAuth
      clientId={ADB2C_CLIENT_ID}
      domain={ADB2C_DOMAIN}
      tenant={ADB2C_TENANT}
      onPasswordReset={() => setBackdrop(true)}
    >
      <RacwaThemeProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Backdrop />
          <BrowserRouter>
            <CaptureQueryParameter
              parameter="policyNumber"
              onCapture={(value) => sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, value)}
            />
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <LoginStatusProvider clientId={ADB2C_CLIENT_ID} useCheckLoginStatus={useCheckLoginStatus}>
                <SessionStatusProvider useCreateSession={useCreateSession}>
                  <Views />
                </SessionStatusProvider>
                <CheckMemberLinkedWrapper />
                <WebChat useGetWebChatInformation={useGetWebChatInformation} />
              </LoginStatusProvider>
            </SessionStatePrefixProvider>
            <TrackPageChanges untrackedPages={[PRE_FORM_BEFORE_YOU_START_URL, ERROR_SYSTEM_UNAVAILABLE_PAGE_URL]} />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </RacwaThemeProvider>
    </RacwaAuth>
  );
};

const App = () => (
  <RecoilRoot>
    <AppInternal />
  </RecoilRoot>
);

export default App;
