import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, Typography } from "@mui/material";
import { FadeWiggleIcon } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const StyledFadeWiggleIcon = styled(FadeWiggleIcon)({
  color: colors.dieselDeeper,
  paddingBottom: "8px",
});

export const StyledIcon = styled(FontAwesomeIcon)({
  color: colors.dieselDeeper,
  paddingBottom: "8px",
});

export const StyledTitle = styled(Typography)({
  fontWeight: 600,
});

export const StyledSubtitle = styled(Typography)({
  fontWeight: 400,
  color: colors.dieselDeep,
});
