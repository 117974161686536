export const SESSION_KEY_PREFIX = "RAC_CMG_";
export const NON_BREAKING_SPACE = "\u00a0";

export const STORAGE_KEY_POLICY_NUMBER = `${SESSION_KEY_PREFIX}POLICY_NUMBER`;
export const STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED = `${SESSION_KEY_PREFIX}_LOGIN_REGISTER_REDIRECT_TRIGGERED`;
export const STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW = `${SESSION_KEY_PREFIX}_HAS_ATTEMPTED_TO_ENTER_FLOW`;
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

export const HIDE_NCB = true;

export enum BackdropMessages {
  ProcessingYourClaim = "Processing your claim\nPlease wait",
}
