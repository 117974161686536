import BeforeYouStart from "../../views/BeforeYouStart";
import Confirmation from "../../views/Confirmation";
import ContactDetails from "../../views/ContactDetails";
import DontHaveAPolicy from "../../views/DontHaveAPolicy";
import ReviewYourClaim from "../../views/ReviewYourClaim";
import SessionTimeout from "../../views/SessionTimeout";
import StartYourClaim from "../../views/StartYourClaim";
import SystemUnavailable from "../../views/SystemUnavailable";
import YourGlassRepairs from "../../views/YourGlassRepairs";
import YourPolicy from "../../views/YourPolicy";

export enum PreFormRoute {
  BeforeYouStart = "before-you-start",
}

export enum FormRoute {
  ContactDetails = "contact-details",
  YourPolicy = "your-policy",
  StartYourClaim = "start-your-claim",
  YourGlassRepairs = "your-glass-repairs",
  ReviewYourClaim = "review-your-claim",
  Confirmation = "confirmation",
}

export enum ErrorRoute {
  DontHaveAPolicy = "dont-have-a-policy",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum UtilityRoute {
  Redirect = "redirect",
}

export enum PreFormPageName {
  BeforeYouStart = "Before you start",
}

export enum FormPageName {
  ContactDetails = "Contact details",
  YourPolicy = "Your policy",
  StartYourClaim = "Start your claim",
  YourGlassRepairs = "Your glass repairs",
  ReviewYourClaim = "Review your claim",
  Confirmation = "Confirmation",
}

export enum ErrorPageName {
  DontHaveAPolicy = "Don't have a policy",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum PreFormPageHeading {
  BeforeYouStart = "Motor glass claim",
}

export enum ErrorPageHeading {
  DontHaveAPolicy = "Uh oh!",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

export type AppRoute = PreFormRoute | FormRoute | ErrorRoute | UtilityRoute;

export const getRouteUrl = (route: AppRoute) => `${process.env.PUBLIC_URL}/${route}`;

export const UTILITY_REDIRECT_URL = getRouteUrl(UtilityRoute.Redirect);
export const PRE_FORM_BEFORE_YOU_START_URL = getRouteUrl(PreFormRoute.BeforeYouStart);
export const ERROR_SESSION_TIMEOUT_PAGE_URL = getRouteUrl(ErrorRoute.SessionTimeout);
export const ERROR_SYSTEM_UNAVAILABLE_PAGE_URL = getRouteUrl(ErrorRoute.SystemUnavailable);
export const ERROR_DONT_HAVE_A_POLICY_PAGE_URL = getRouteUrl(ErrorRoute.DontHaveAPolicy);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const preFormRouteInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.BeforeYouStart]: {
    key: PreFormRoute.BeforeYouStart,
    path: getRouteUrl(PreFormRoute.BeforeYouStart),
    name: PreFormPageName.BeforeYouStart,
    heading: PreFormPageHeading.BeforeYouStart,
    element: <BeforeYouStart />,
  },
};

export const formRouteInfo: Record<FormRoute, RouteInformation> = {
  [FormRoute.ContactDetails]: {
    key: FormRoute.ContactDetails,
    path: getRouteUrl(FormRoute.ContactDetails),
    name: FormPageName.ContactDetails,
    element: <ContactDetails />,
  },
  [FormRoute.YourPolicy]: {
    key: FormRoute.YourPolicy,
    path: getRouteUrl(FormRoute.YourPolicy),
    name: FormPageName.YourPolicy,
    element: <YourPolicy />,
  },
  [FormRoute.StartYourClaim]: {
    key: FormRoute.StartYourClaim,
    path: getRouteUrl(FormRoute.StartYourClaim),
    name: FormPageName.StartYourClaim,
    element: <StartYourClaim />,
  },
  [FormRoute.YourGlassRepairs]: {
    key: FormRoute.YourGlassRepairs,
    path: getRouteUrl(FormRoute.YourGlassRepairs),
    name: FormPageName.YourGlassRepairs,
    element: <YourGlassRepairs />,
  },
  [FormRoute.ReviewYourClaim]: {
    key: FormRoute.ReviewYourClaim,
    path: getRouteUrl(FormRoute.ReviewYourClaim),
    name: FormPageName.ReviewYourClaim,
    element: <ReviewYourClaim />,
  },
  [FormRoute.Confirmation]: {
    key: FormRoute.Confirmation,
    path: getRouteUrl(FormRoute.Confirmation),
    name: FormPageName.Confirmation,
    element: <Confirmation />,
  },
};

export const errorRouteInfo: Record<ErrorRoute, RouteInformation> = {
  [ErrorRoute.DontHaveAPolicy]: {
    key: ErrorRoute.DontHaveAPolicy,
    path: getRouteUrl(ErrorRoute.DontHaveAPolicy),
    name: ErrorPageName.DontHaveAPolicy,
    element: <DontHaveAPolicy />,
    heading: ErrorPageHeading.DontHaveAPolicy,
  },
  [ErrorRoute.SessionTimeout]: {
    key: ErrorRoute.SessionTimeout,
    path: getRouteUrl(ErrorRoute.SessionTimeout),
    name: ErrorPageName.SessionTimeout,
    element: <SessionTimeout />,
    heading: ErrorPageHeading.SessionTimeout,
  },
  [ErrorRoute.SystemUnavailable]: {
    key: ErrorRoute.SystemUnavailable,
    path: getRouteUrl(ErrorRoute.SystemUnavailable),
    name: ErrorPageName.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: ErrorPageHeading.SystemUnavailable,
  },
};

export const ErrorRoutePaths = Object.values(errorRouteInfo).map((route) => route.path);
export const PreFormRoutePaths = Object.values(preFormRouteInfo).map((route) => route.path);

export const preFormRoutes: RouteInformation[] = Object.values(preFormRouteInfo);
export const formRoutes: RouteInformation[] = Object.values(formRouteInfo);
export const errorRoutes: RouteInformation[] = Object.values(errorRouteInfo);
export const allRoutes = [...preFormRoutes, ...formRoutes, ...errorRoutes];
export const standardPageRoutes = [...preFormRoutes, ...errorRoutes];
