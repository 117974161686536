import { Box, Divider, Grid, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { CarGRFX001NoPadding, PolicyDetailsCard } from "@racwa/react-components";
import { format } from "date-fns";
import { BASE_PDS_URL, ClaimNumberDisplay } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { GlassOptions } from "../../shared/constants/GlassOptions";
import { StyledCallToAction } from "../styled";
import {
  StyledGlassDamageText,
  StyledGridItemPadding,
  StyledGridItemPaddingFirst,
  StyledSummaryContainer,
  StyledSummaryContainerHeader,
} from "./styled";
import { ReviewYourClaimFormProps } from "./types";

export const ReviewYourClaimForm: React.FC<ReviewYourClaimFormProps> = ({
  form,
  onSubmit,
  policyDetails,
  claimNumber,
  claimStartDate,
  glassRepairOption,
  glassDamageOption,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const nextButtonId = "submit-button";
  const pdsText = "Product Disclosure Statement";

  const getGlassRepairOptionDisplayValue = (id: string | undefined) => {
    let displayValue = "";
    GlassOptions.some((item) => {
      if (item.id === id) {
        displayValue = item.display;
        return true;
      }
      return false;
    });
    return displayValue;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Review and submit your claim
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ClaimNumberDisplay number={claimNumber} />
          </Grid>
          <Grid item xs={12}>
            <StyledSummaryContainer>
              <Grid item xs={12}>
                <StyledSummaryContainerHeader variant="h3" id="header" data-testid="header">
                  Your claim summary
                </StyledSummaryContainerHeader>
              </Grid>
              <PolicyDetailsCard
                id={"summary"}
                policyTitle={`${policyDetails?.year} ${policyDetails?.make}`}
                policySubtitle={policyDetails?.registrationNumber}
                minWidth={"50"}
                maxWidth={"200"}
                cardActionTitle={pdsText}
                cardActionLink={BASE_PDS_URL}
                cardActionOnClick={() => {
                  gtm(event(pdsText));
                }}
                showCancelledRibbon={false}
                properties={[{ key: "Policy number", value: policyDetails?.policyNumber ?? "" }]}
              >
                <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
                  <CarGRFX001NoPadding />
                </Box>
              </PolicyDetailsCard>
              <StyledGridItemPaddingFirst item xs={12}></StyledGridItemPaddingFirst>
              <Divider />
              <StyledGridItemPadding item xs={12}>
                <Typography variant="body1">
                  <b>Date of damage</b>
                </Typography>
                <Typography variant="body1" id="claim-summary-date-of-damage-text">
                  {claimStartDate && format(new Date(claimStartDate), "dd/MM/yyyy")}
                </Typography>
              </StyledGridItemPadding>
              <Divider />
              <StyledGridItemPadding item xs={12}>
                <Typography variant="body1">
                  <b>Glass fixed</b>
                </Typography>
                <Typography variant="body1" id="claim-summary-glass-repair-text">
                  {getGlassRepairOptionDisplayValue(glassRepairOption)}
                </Typography>
              </StyledGridItemPadding>
              {glassDamageOption && glassDamageOption.length > 0 && (
                <>
                  <Divider />
                  <StyledGridItemPadding item xs={12}>
                    <Typography variant="body1">
                      <b>Glass damaged</b>
                    </Typography>
                    {glassDamageOption.map((item, index) => (
                      <Typography key={item.key} variant="body1" id={`claim-summary-glass-damage-text-${index}`}>
                        {item.label}
                      </Typography>
                    ))}
                  </StyledGridItemPadding>
                </>
              )}
            </StyledSummaryContainer>
            {glassDamageOption && glassDamageOption.length > 0 && (
              <StyledGlassDamageText item xs={12}>
                <Typography paragraph variant="body1">
                  If we can, we'll assign you one of our repairers straight away.
                </Typography>
                <Typography variant="body1">They'll collect your excess, if there is one.</Typography>
              </StyledGlassDamageText>
            )}
            <Grid item xs={12}>
              <StyledCallToAction
                type="submit"
                id={nextButtonId}
                data-testid={nextButtonId}
                color="primary"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
              >
                Submit claim
              </StyledCallToAction>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReviewYourClaimForm;
